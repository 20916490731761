import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import { CREATE_GRUPO_CONTROLE_INIT, DELETE_GRUPO_CONTROLE_INIT, LIST_GRUPO_CONTROLE_INIT, UPDATE_GRUPO_CONTROLE_INIT } from '../actionTypes';
import { listGrupoControleSuccess, errorGrupoControleMsg, createGrupoControleSuccess, updateGrupoControleSuccess, deleteGrupoControleSuccess } from './actions';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { User as RealmUser } from 'realm-web';
import { FarmaFuncaoError } from 'src/integracoes/modelos/erros';
import { IEmpresaGrupoControle } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';

interface FetchListGrupoControleResult {
  isError: boolean;
  gruposControle: IEmpresaGrupoControle[] | null;
  msg: string | null;
}

// ------------  LISTA GRUPOS DE CONTROLE --------------------

const listaGruposControle = async ({ empId, usuarioMongoAppServices }: { empId: string, usuarioMongoAppServices: RealmUser }) => {
  try {
    const { status, dados, mensagem } = await Empresas.obtemGruposControle(usuarioMongoAppServices, empId);
    if (status) {
      return {
        isError: false,
        gruposControle: dados,
        msg: mensagem
      };
    }
    return {
      isError: true,
      gruposControle: null,
      msg: mensagem
    };
  } catch (error: any) {
    throw new FarmaFuncaoError(error);
  }
};

export function* parseFetchListaGruposControle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId } = payload;

  try {
    const grupoControleData: FetchListGrupoControleResult = yield call(listaGruposControle, { usuarioMongoAppServices, empId });
    if (!grupoControleData.isError) {
      yield put(listGrupoControleSuccess(grupoControleData));
    } else {
      yield put(errorGrupoControleMsg(grupoControleData.msg));
    }
  } catch (error: any) {
    yield put(errorGrupoControleMsg({ msg: error.mensagem }));
  }
}

export function* watchListaGruposControle() {
  yield takeEvery(LIST_GRUPO_CONTROLE_INIT, parseFetchListaGruposControle);
}

// ----------------------- CRIA GRUPO CONTROLE --------------------//

const criaGrupoControle = async ({ empId, usuarioMongoAppServices, grupoControle }: { empId: string, usuarioMongoAppServices: RealmUser, grupoControle: IEmpresaGrupoControle }) => {
  try {
    const { status, mensagem } = await Empresas.criaGrupoControle(usuarioMongoAppServices, empId, grupoControle);
    if (status) {
      return {
        isError: false,
        msg: mensagem
      };
    }
    return {
      isError: true,
      msg: mensagem
    };
  } catch (error: any) {
    throw new FarmaFuncaoError(error);
  }
};

export function* parseFetchCriaGrupoControle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId, grupoControle } = payload;

  try {
    const grupoControleData: FetchListGrupoControleResult = yield call(criaGrupoControle, { usuarioMongoAppServices, empId, grupoControle });
    if (!grupoControleData.isError) {
      yield put(createGrupoControleSuccess({ isCreated: true, msg: grupoControleData.msg }));
    } else {
      yield put(errorGrupoControleMsg({ msg: grupoControleData.msg }));
    }
  } catch (error: any) {
    yield put(errorGrupoControleMsg({ msg: error.mensagem }));
  }
}

export function* watchCriaGrupoControle() {
  yield takeEvery(CREATE_GRUPO_CONTROLE_INIT, parseFetchCriaGrupoControle);
}


// ----------------------- ATUALIZA GRUPO CONTROLE --------------------//

const atualizaGrupoControle = async ({ empId, usuarioMongoAppServices, grupoControle }: { empId: string, usuarioMongoAppServices: RealmUser, grupoControle: IEmpresaGrupoControle }) => {
  try {
    const { status, mensagem } = await Empresas.atualizaGrupoControle(usuarioMongoAppServices, empId, grupoControle);
    if (status) {
      return {
        isError: false,
        msg: mensagem
      };
    }
    return {
      isError: true,
      msg: mensagem
    };
  } catch (error: any) {
    throw new FarmaFuncaoError(error);
  }
};

export function* parseFetchAtualizaGrupoControle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId, grupoControle } = payload;

  try {
    const grupoControleData: FetchListGrupoControleResult = yield call(atualizaGrupoControle, { usuarioMongoAppServices, empId, grupoControle });
    if (!grupoControleData.isError) {
      yield put(updateGrupoControleSuccess({ isUpdated: true, msg: grupoControleData.msg }));
    } else {
      yield put(errorGrupoControleMsg({ msg: grupoControleData.msg }));
    }
  } catch (error: any) {
    yield put(errorGrupoControleMsg({ msg: error.mensagem }));
  }
}

export function* watchAtualizaGrupoControle() {
  yield takeEvery(UPDATE_GRUPO_CONTROLE_INIT, parseFetchAtualizaGrupoControle);
}

// ----------------------- REMOVE GRUPO CONTROLE --------------------//

const removeGrupoControle = async ({ empId, usuarioMongoAppServices, grupoControleId }: { empId: string, usuarioMongoAppServices: RealmUser, grupoControleId: string }) => {
  try {
    const { status, mensagem } = await Empresas.removeGrupoControle(usuarioMongoAppServices, empId, grupoControleId);
    if (status) {
      return {
        isError: false,
        msg: mensagem
      };
    }
    return {
      isError: true,
      msg: mensagem
    };
  } catch (error: any) {
    throw new FarmaFuncaoError(error);
  }
};

export function* parseFetchRemoveGrupoControle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId, grupoControleId } = payload;

  try {
    const grupoControleData: FetchListGrupoControleResult = yield call(removeGrupoControle, { usuarioMongoAppServices, empId, grupoControleId });
    if (!grupoControleData.isError) {
      yield put(deleteGrupoControleSuccess({ isDeleted: true, msg: grupoControleData.msg }));
    } else {
      yield put(errorGrupoControleMsg({ msg: grupoControleData.msg }));
    }
  } catch (error: any) {
    yield put(errorGrupoControleMsg({ msg: error.mensagem }));
  }
}

export function* watchRemoveGrupoControle() {
  yield takeEvery(DELETE_GRUPO_CONTROLE_INIT, parseFetchRemoveGrupoControle);
}

export default function* rootSaga() {
  yield all([
    fork(watchListaGruposControle),
    fork(watchCriaGrupoControle),
    fork(watchAtualizaGrupoControle),
    fork(watchRemoveGrupoControle)
  ]);
}
