import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { IClassificacao } from '../modelos/IClassificacaoLoja';

// ------------------------ OBTEM LISTA CLASSIFICAÇÃO ------------------------ //
const obtemClassificacoes = async (
  usuarioMongoAppServices: RealmUser
): Promise<IRespostaFuncao<IClassificacao[]>> => {
  return usuarioMongoAppServices.functions['classificacoes/obtemClassificacao']()
    .then((resultado) => resultado as IRespostaFuncao<IClassificacao[]>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter lista de Classificações de Loja',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ CRIAR CLASSIFICAÇÃO ------------------------ //
const cria = async (
  usuarioMongoAppServices: RealmUser,
  classificacao: IClassificacao
): Promise<IRespostaFuncao<IClassificacao>> => {
  return usuarioMongoAppServices.functions['classificacoes/criaClassificacao'](classificacao)
    .then((resultado) => resultado as IRespostaFuncao<IClassificacao>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao criar Classificação de Loja',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ ATUALIZAR CLASSIFICAÇÃO ------------------------ //
const atualiza = async (
  usuarioMongoAppServices: RealmUser,
  classificacao: IClassificacao
): Promise<IRespostaFuncao<IClassificacao>> => {
  return usuarioMongoAppServices.functions['classificacoes/atualizaClassificacao'](classificacao)
    .then((resultado) => resultado as IRespostaFuncao<IClassificacao>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao atualizar Classificação de Loja',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

// ------------------------ DELETAR CLASSIFICAÇÃO ------------------------ //
const deleta = async (
  usuarioMongoAppServices: RealmUser,
  classificacaoId: { id: string }
): Promise<IRespostaFuncao<any>> => {
  return usuarioMongoAppServices.functions['classificacoes/removeClassificacao'](classificacaoId)
    .then((resultado) => resultado as IRespostaFuncao<any>)
    .catch((e: any) => {
      const erro = {
        nome: 'MONGODB-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao remover Classificação de Loja',
        causa: e
      };
      throw new FarmaError(erro);
    });
};

export const Classificacao = {
  obtemClassificacoes,
  cria,
  atualiza,
  deleta
}