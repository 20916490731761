/* eslint-disable prettier/prettier */
import {
  PAPEL4,
  PAPEL3,
  PAPEL2,
  PAPEL1,
  PAPELDEFAULT,
  PAPEL5
} from 'src/utils/rbac/papeis';
import { primaryColor } from './config';
import {
  Trello as TrelloIcon,
  PieChart as PieChartIcon,
  CheckCircle as CheckCircleIcon,
  Settings as SettingsIcon,
} from 'react-feather';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import ApartmentIcon from '@material-ui/icons/AssessmentOutlined';

export const APP_VERSION = '0.1.0';
export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT'
};
export const BRAND_PRIMARY_COLOR = primaryColor;

export const PTBR_TABLE_TRANSLATE = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'Linhas',
    labelRowsPerPage: 'Linhas por página',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página'
  },
  toolbar: {
    nRowsSelected: '{0} linha(s) selecionada(s)',
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquisar'
  },
  header: {
    actions: 'Ações'
  },
  body: {
    emptyDataSourceMessage: 'Lista vazia',
    addTooltip: 'Adicionar',
    deleteTooltip: 'Deletar',
    editTooltip: 'Editar',
    filterRow: {
      filterTooltip: 'Filtrar'
    },
    editRow: {
      deleteText: 'Tem certeza de que deseja deletar este item?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Salvar'
    }
  }
};

export const styleTable = {
  headerStyle: {
    borderLeft: '1px solid #f2f2f2',
    borderRight: '1px solid #f2f2f2',
    color: BRAND_PRIMARY_COLOR
  },
  cellStyle: {
    borderLeft: '1px solid #f2f2f2',
    borderRight: '1px solid #f2f2f2'
  }
};


export const meses = [
  { id: 1, name: 'Janeiro' },
  { id: 2, name: 'Fevereiro' },
  { id: 3, name: 'Março' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Maio' },
  { id: 6, name: 'Junho' },
  { id: 7, name: 'Julho' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Setembro' },
  { id: 10, name: 'Outubro' },
  { id: 11, name: 'Novembro' },
  { id: 12, name: 'Dezembro' }
];

export const statusEmpresa = [
  { id: 1, status: 'Ativa' },
  { id: 2, status: 'Inativa' }
];

export const todosNiveisAcesso = [
  {
    id: 1,
    nivel: PAPELDEFAULT,
    nome: '',
    visivel: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
  },
  {
    id: 2,
    nivel: PAPEL1,
    nome: 'Visualização',
    visivel: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
  },
  {
    id: 3,
    nivel: PAPEL2,
    nome: 'Gestão',
    visivel: [PAPEL3, PAPEL4, PAPEL5]
  },
  {
    id: 4,
    nivel: PAPEL3,
    nome: 'Administração',
    visivel: [PAPEL4]
  },
  {
    id: 5,
    nivel: PAPEL4,
    nome: 'Super Admin',
    visivel: ['']
  },
  {
    id: 6,
    nivel: PAPEL5,
    nome: 'Administração WL',
    visivel: [PAPEL4]
  }
];

export const valoresDefaultCurvaAbc = [
  { id: 'A', categoria: 'A', porcentagem: 60 },
  { id: 'B', categoria: 'B', porcentagem: 30 },
  { id: 'C', categoria: 'C', porcentagem: 10 }
];

export const valorDefaultParametroD = {
  id: 'D',
  categoria: 'D',
  dias: 90
};

export const IMG_ERROR = '/static/img-error.svg';
export const LOGO_ERROR = '/static/logo-error.svg';
export const IMG_FUNDO = '/static/login-bg.svg';

// ------ nomes atributos salvos banco de dados
export const ticketMedio = 'ticket';
export const numeroCupons = 'cupons';

export const sectionsMenu = [
  {
    subheader: 'Relatórios',
    allowedRoles: [PAPEL1, PAPEL2],
    items: []
  },
  {
    subheader: 'Configurações',
    allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
    items: [
      {
        title: 'Usuários',
        icon: GroupAddOutlinedIcon,
        href: '/gerenciar-usuarios/lista',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
      },
      {
        title: 'Grupos de Controle',
        icon: FindInPageOutlinedIcon,
        href: '/grupos-controle',
        allowedRoles: [PAPEL3, PAPEL4, PAPEL5]
      },
      {
        title: 'Metas',
        icon: EmojiEventsOutlinedIcon,
        href: '/metas',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Nova Meta',
            href: '/metas/nova',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Acessa Lista',
            href: '/metas/gerenciar',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Colaboradores',
            href: '/gerenciar-colaborador/lista',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      },
      {
        title: 'Campanhas',
        icon: BeenhereOutlinedIcon,
        href: '/metas',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Nova Campanha',
            href: '/campanha/nova',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Acessa Lista',
            href: '/campanha/gerenciar',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      },
      {
        title: 'Fator de Correção',
        icon: CheckCircleIcon,
        href: '/fator',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Novo Fator de Correção',
            href: '/fator/nova',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Acessa Lista',
            href: '/fator/gerenciar',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      },
      {
        title: 'Parâmetros de Estoque',
        icon: SettingsIcon,
        href: '/configuracoes',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Curva ABC',
            href: '/configuracoes/curvaAbc',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Excesso de Estoque',
            href: '/configuracoes/estoque',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      }
    ]
  },
  {
    subheader: 'Administração',
    allowedRoles: [PAPEL3, PAPEL4, PAPEL5],
    items: [
      {
        title: 'Empresas',
        icon: TrelloIcon,
        href: '/gerenciar-empresas',
        allowedRoles: [PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Acessa Lista',
            href: '/gerenciar-empresas/lista',
            allowedRoles: [PAPEL3, PAPEL4, PAPEL5],
          },
          {
            title: 'Tipos',
            href: '/gerenciar-tipos-dash',
            allowedRoles: [PAPEL4],
          },
          {
            title: 'Categorias',
            href: '/gerenciar-categorias-dash',
            allowedRoles: [PAPEL4],
          },
          {
            title: 'Dashboards',
            href: '/criar-dashboards',
            allowedRoles: [PAPEL4],
          },
          {
            title: 'Classificações de Loja',
            href: '/classificacoes-loja',
            allowedRoles: [PAPEL4, PAPEL5]
          }
        ]
      }
    ]
  }
];
