// modulos
export const ACCOUNT = 'account';
export const CAMPANHA = 'campanha';
export const DASHTYPE = 'dashType';
export const CATEGORIA = 'categoria';
export const DASHBOARD = 'dashboard';
export const EMPRESA = 'empresa';
export const FATOR = 'fator';
export const META = 'meta';
export const USER = 'user';
export const GRUPO = 'grupo';
export const GRUPO_CONTROLE = 'grupo-de-controle';
export const LOJA = 'loja';
export const COLABORADOR = 'colaborador';
export const CURVA_ABC = 'curva-abc';
export const EXCESSO_ESTOQUE = 'excesso-de-estoque';
export const LOGIN_ADMIN = 'login-admin';
export const STATUS_EMPRESA = 'status-empresa';
export const CLASSIFICACAO_DE_LOJA = 'classificacao-de-loja';

// permissions
export const CREATE = 'create';
export const READ = 'read';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const ASSIGN = 'assign';
