import {
  LIST_GRUPO_CONTROLE_INIT,
  LIST_GRUPO_CONTROLE_SUCCESS,
  ERROR_GRUPO_CONTROLE_MSG,
  CLEAR_GRUPO_CONTROLE,
  CREATE_GRUPO_CONTROLE_INIT,
  CREATE_GRUPO_CONTROLE_SUCCESS,
  UPDATE_GRUPO_CONTROLE_SUCCESS,
  UPDATE_GRUPO_CONTROLE_INIT,
  DELETE_GRUPO_CONTROLE_INIT,
  DELETE_GRUPO_CONTROLE_SUCCESS
} from '../actionTypes';

const INIT_STATE = {
  gruposControle: [],
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  isError: false,
  msg: '',
  error: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_GRUPO_CONTROLE_INIT: {
      return {
        ...state,
        loading: true
      };
    }
    case LIST_GRUPO_CONTROLE_SUCCESS: {
      const { gruposControle } = action.payload;
      return {
        ...state,
        gruposControle,
        loading: false
      };
    }

    case CREATE_GRUPO_CONTROLE_INIT:
      return { ...state, isCreated: false };
    case CREATE_GRUPO_CONTROLE_SUCCESS: {
      const { isCreated, msg } = action.payload;
      return {
        ...state,
        msg,
        isCreated
      };
    }

    case UPDATE_GRUPO_CONTROLE_INIT: {
      return {
        ...state,
        isUpdated: false
      };
    }
    case UPDATE_GRUPO_CONTROLE_SUCCESS: {
      const { isUpdated, msg } = action.payload;
      return {
        ...state,
        msg,
        isUpdated
      };
    }

    case DELETE_GRUPO_CONTROLE_INIT:
      return { ...state, isDeleted: false };
    case DELETE_GRUPO_CONTROLE_SUCCESS: {
      const { isDeleted, msg } = action.payload;
      return {
        ...state,
        isDeleted,
        msg
      };
    }

    case ERROR_GRUPO_CONTROLE_MSG: {
      const { msg } = action.payload;
      return {
        ...state,
        msg,
        isError: true
      };
    }

    case CLEAR_GRUPO_CONTROLE:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isError: false,
        msg: ''
      };

    default:
      return { ...state };
  }
};
