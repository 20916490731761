import {
  LIST_CLASSIFICACAO_INIT,
  LIST_CLASSIFICACAO_SUCCESS,
  CREATE_CLASSIFICACAO_INIT,
  CREATE_CLASSIFICACAO_SUCCESS,
  UPDATE_CLASSIFICACAO_INIT,
  UPDATE_CLASSIFICACAO_SUCCESS,
  DELETE_CLASSIFICACAO_INIT,
  DELETE_CLASSIFICACAO_SUCCESS,
  CLASSIFICACAO_ERROR_MSG,
  CLEAR_CLASSIFICACAO
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  isError: false,
  classificacoes: [],
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CLASSIFICACAO_INIT:
      return { ...state, loading: true, isUpdated: false };
    case LIST_CLASSIFICACAO_SUCCESS: {
      const { classificacoes } = action.payload;
      return {
        ...state,
        classificacoes,
        loading: false
      };
    }
    case CREATE_CLASSIFICACAO_INIT:
      return {
        ...state,
        loading: true,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case CREATE_CLASSIFICACAO_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isCreated: true,
      };
    }
    case UPDATE_CLASSIFICACAO_INIT:
      return {
        ...state,
        loading: true,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case UPDATE_CLASSIFICACAO_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isUpdated: true,
      };
    }
    case DELETE_CLASSIFICACAO_INIT:
      return {
        ...state,
        loading: true,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case DELETE_CLASSIFICACAO_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isDeleted: true,
      };
    }
    case CLASSIFICACAO_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, isError: true, msg };
    }
    case CLEAR_CLASSIFICACAO: {
      return {
        ...state,
        isUpdated: false,
        isCreated: false,
        isDeleted: false,
        isError: false,
        loading: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
