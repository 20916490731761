import {
  LIST_GRUPO_CONTROLE_INIT,
  LIST_GRUPO_CONTROLE_SUCCESS,
  ERROR_GRUPO_CONTROLE_MSG,
  CLEAR_GRUPO_CONTROLE,
  CREATE_GRUPO_CONTROLE_INIT,
  CREATE_GRUPO_CONTROLE_SUCCESS,
  UPDATE_GRUPO_CONTROLE_INIT,
  UPDATE_GRUPO_CONTROLE_SUCCESS,
  DELETE_GRUPO_CONTROLE_INIT,
  DELETE_GRUPO_CONTROLE_SUCCESS
} from '../actionTypes';

export const listGrupoControleInit = (data) => ({
  type: LIST_GRUPO_CONTROLE_INIT,
  payload: data
});

export const listGrupoControleSuccess = (data) => ({
  type: LIST_GRUPO_CONTROLE_SUCCESS,
  payload: data
});

export const createGrupoControleInit = (data) => ({
  type: CREATE_GRUPO_CONTROLE_INIT,
  payload: data
});

export const createGrupoControleSuccess = (data) => ({
  type: CREATE_GRUPO_CONTROLE_SUCCESS,
  payload: data
});

export const updateGrupoControleInit = (data) => ({
  type: UPDATE_GRUPO_CONTROLE_INIT,
  payload: data
});

export const updateGrupoControleSuccess = (data) => ({
  type: UPDATE_GRUPO_CONTROLE_SUCCESS,
  payload: data
});

export const deleteGrupoControleInit = (data) => ({
  type: DELETE_GRUPO_CONTROLE_INIT,
  payload: data
});

export const deleteGrupoControleSuccess = (data) => ({
  type: DELETE_GRUPO_CONTROLE_SUCCESS,
  payload: data
});

export const errorGrupoControleMsg = (data) => ({
  type: ERROR_GRUPO_CONTROLE_MSG,
  payload: data
});

export const clearGrupoControle = (data) => ({
  type: CLEAR_GRUPO_CONTROLE,
  payload: data
});
