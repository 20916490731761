import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LIST_CLASSIFICACAO_INIT,
  CREATE_CLASSIFICACAO_INIT,
  UPDATE_CLASSIFICACAO_INIT,
  DELETE_CLASSIFICACAO_INIT
} from '../actionTypes';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { classificacaoErrorMsg, createClassificacaoSuccess, deleteClassificacaoSuccess, listClassificacaoSuccess, updateClassificacaoSuccess } from './actions';
import { IClassificacao } from 'src/integracoes/servicos/mongo-atlas/modelos/IClassificacaoLoja';
import { Classificacao } from 'src/integracoes/servicos/mongo-atlas/funcoes/classificacoes';
import { User as RealmUser } from 'realm-web';

interface FetchListaClassificacaoResult {
  isError: boolean;
  classificacoes: IClassificacao[] | null;
  errorMessage: string | null;
}

// -------------------Listar Classificações-----------------------------

const listarClassificacao = async ({ usuarioMongoAppServices }: { usuarioMongoAppServices: RealmUser }) => {
  try {
    const { status, dados, mensagem } = await Classificacao.obtemClassificacoes(usuarioMongoAppServices); // alterar função Categorias

    if (status) {
      return {
        isError: !status,
        classificacoes: dados,
        errorMessage: null
      };
    }
    return {
      isError: true,
      classificacoes: null,
      errorMessage: mensagem
    };
  } catch (error: any) {

    throw new FarmaError(error);
  }
};

function* parseFetchListaClassificacao({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices } = payload;
  try {
    const resp: FetchListaClassificacaoResult = yield call(listarClassificacao, { usuarioMongoAppServices });
    if (!resp.isError) {
      yield put(listClassificacaoSuccess({ classificacoes: resp.classificacoes }));
    } else {
      yield put(classificacaoErrorMsg({ msg: resp.errorMessage }));
    }
  } catch (error: any) {
    yield put(classificacaoErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchListarClassificacao() {
  yield takeEvery(LIST_CLASSIFICACAO_INIT, parseFetchListaClassificacao);
}


// ---------------------------Criar Classificação de loja---------------------------------------------------------

const createClassificacaoAsync = async ({ usuarioMongoAppServices, classificacao }:
  { usuarioMongoAppServices: RealmUser, classificacao: IClassificacao }
) => {
  try {
    const { status, dados, mensagem } = await Classificacao.cria(usuarioMongoAppServices, classificacao);
    if (status) {
      return {
        isCreated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isCreated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* createClassificacaoMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, classificacao } = payload;

  try {
    const { isCreated, msg, data } = yield call(createClassificacaoAsync, {
      usuarioMongoAppServices,
      classificacao
    });
    if (isCreated) {
      yield put(createClassificacaoSuccess({ msg }));
    } else {
      yield put(classificacaoErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(classificacaoErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchCreateClassificacao() {
  yield takeEvery(CREATE_CLASSIFICACAO_INIT, createClassificacaoMiddle);
}

// ---------------------------atualiza Classificação de loja---------------------------------------------------------

const atualizaClassificacaoAsync = async ({ usuarioMongoAppServices, classificacao }:
  { usuarioMongoAppServices: RealmUser, classificacao: IClassificacao }
) => {
  try {
    const { status, dados, mensagem } = await Classificacao.atualiza(usuarioMongoAppServices, classificacao);
    if (status) {
      return {
        isUpdated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isUpdated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* atualizaClassificacaoMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, classificacao } = payload;
  try {
    const { isUpdated, msg } = yield call(atualizaClassificacaoAsync, {
      usuarioMongoAppServices,
      classificacao
    });
    if (isUpdated) {
      yield put(updateClassificacaoSuccess({ msg }));
    } else {
      yield put(classificacaoErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(classificacaoErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchAtualizaClassificacao() {
  yield takeEvery(UPDATE_CLASSIFICACAO_INIT, atualizaClassificacaoMiddle);
}

// ---------------------------Deletar Classificação de loja---------------------------------------------------------

const deleteClassificacaoAsync = async ({ usuarioMongoAppServices, classificacaoId }:
  { usuarioMongoAppServices: RealmUser, classificacaoId: string }
) => {
  try {
    const { status, dados, mensagem } = await Classificacao.deleta(usuarioMongoAppServices, { id: classificacaoId });
    if (status) {
      return {
        isDeleted: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isDeleted: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* deleteClassificacaoMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, classificacaoId } = payload;
  try {
    const { isDeleted, msg, data } = yield call(deleteClassificacaoAsync, {
      usuarioMongoAppServices,
      classificacaoId
    });
    if (isDeleted) {
      yield put(deleteClassificacaoSuccess({ msg }));
    } else {
      yield put(classificacaoErrorMsg({ msg }));
    }
  } catch (error: any) {
    yield put(classificacaoErrorMsg({ msg: error.mensagem }));
  }
}

export function* watchDeleteClassificacao() {
  yield takeEvery(DELETE_CLASSIFICACAO_INIT, deleteClassificacaoMiddle);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchListarClassificacao),
    fork(watchCreateClassificacao),
    fork(watchDeleteClassificacao),
    fork(watchAtualizaClassificacao)
  ]);
}
