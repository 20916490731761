import {
  LIST_CLASSIFICACAO_INIT,
  LIST_CLASSIFICACAO_SUCCESS,
  CREATE_CLASSIFICACAO_INIT,
  CREATE_CLASSIFICACAO_SUCCESS,
  UPDATE_CLASSIFICACAO_INIT,
  UPDATE_CLASSIFICACAO_SUCCESS,
  DELETE_CLASSIFICACAO_INIT,
  DELETE_CLASSIFICACAO_SUCCESS,
  CLASSIFICACAO_ERROR_MSG,
  CLEAR_CLASSIFICACAO
} from '../actionTypes';

export const listClassificacaoInit = (data) => ({
  type: LIST_CLASSIFICACAO_INIT,
  payload: data
});

export const listClassificacaoSuccess = (data) => {
  return {
    type: LIST_CLASSIFICACAO_SUCCESS,
    payload: data
  };
};

export const createClassificacaoInit = (data) => ({
  type: CREATE_CLASSIFICACAO_INIT,
  payload: data
});

export const createClassificacaoSuccess = (data) => ({
  type: CREATE_CLASSIFICACAO_SUCCESS,
  payload: data
});

export const updateClassificacaoInit = (data) => ({
  type: UPDATE_CLASSIFICACAO_INIT,
  payload: data
});

export const updateClassificacaoSuccess = (data) => ({
  type: UPDATE_CLASSIFICACAO_SUCCESS,
  payload: data
});

export const deleteClassificacaoInit = (data) => ({
  type: DELETE_CLASSIFICACAO_INIT,
  payload: data
});

export const deleteClassificacaoSuccess = (data) => ({
  type: DELETE_CLASSIFICACAO_SUCCESS,
  payload: data
});

export const classificacaoErrorMsg = (data) => ({
  type: CLASSIFICACAO_ERROR_MSG,
  payload: data
});

export const clearClassificacao = (data) => ({
  type: CLEAR_CLASSIFICACAO,
  payload: data
});

