import { isNaN } from 'lodash';
import { User } from '../@types/user';
import { Loja } from '../@types/emp';
import { ArquivoSessionStorage } from './modelos';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { firebaseStorage } from 'src/integracoes/servicos/firebase/storage';
import { BRAND_PRIMARY_COLOR } from 'src/constants';

export const convertArrayToObject = (
  array: any[],
  key: string,
  key2 = ''
): any => {
  const initialValue = {};
  const keyItem = key2 === '' ? key : key2;
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[keyItem]
    };
  }, initialValue);
};
export const filterUsersEmail = (allUsers: User[]): any => {
  const arrayUsers = allUsers.map(({ email }) => {
    return {
      email: email
    };
  });
  return convertArrayToObject(arrayUsers, 'email');
};

type GetNameLoja = {
  lojas: Loja[];
  idLoja: string;
};

export const getNameLoja = ({ lojas, idLoja }: GetNameLoja): string => {
  function searchLojas(arrLoja: Loja): any {
    return arrLoja.id === idLoja;
  }
  const theLoja = lojas.find(searchLojas);
  if (typeof theLoja?.loja !== 'undefined') {
    return theLoja.loja;
  }
  return '';
};

export const getTimeToStamp = (timestamp: any): any => {
  try {
    const date = new Date();
    date.setSeconds(timestamp); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);
  } catch {
    return timestamp;
  }
};

export const filterDashById = (id: any, allDashs: any[]): any => {
  try {
    const filteredDash = allDashs
      .map(({ dashs }) => {
        return dashs
          .filter((dash: any) => dash.dashId === id)
          .map((dashFiltered: any) => {
            return {
              name: dashFiltered.name,
              dku: dashFiltered.dku
            };
          });
      })
      .filter((dash) => dash.some((v: any) => true))[0];
    return filteredDash[0];
  } catch {
    return {
      name: id,
      dku: 'vazio'
    };
  }
};

interface MenuItens {
  baseUrl: string;
  itens: any[];
  empId: string;
  subHeaderName: string;
  subHeaderIdName: string;
  subItemName: string;
  itemName: string;
  papeis: string[];
  title: string;
}

export const listMenuUrl = ({
  baseUrl,
  itens,
  empId,
  subHeaderName,
  subHeaderIdName,
  itemName,
  subItemName,
  papeis
}: MenuItens): { title: string; items: any[] }[] => {
  try {
    const itensList: { title: string; items: any[] }[] = [];

    // Percorre as categorias/dashboards
    itens.map((item: any) => {
      const tempList = {
        title: item[subHeaderName], // O nome da categoria
        href: `/${baseUrl}/${empId}/${item[subHeaderIdName]}`,
        allowedRoles: papeis,
        items: item[itemName].map((sub: any) => {
          return {
            title: sub.name, // Nome do dashboard
            href: `/${baseUrl}/${empId}/${item[subHeaderIdName]}/${sub[subItemName]}`, // URL do dashboard
            icon: sub.icon,  // Ícone
            allowedRoles: papeis,
            svgIcon: true
          };
        })
      };

      // Adiciona a lista de itens/dashboards na lista final
      itensList.push(tempList);
    });

    return itensList;
  } catch {
    return [];
  }
};


/* mascaras  dos inputs    */
// telefone
const formatarNumeroTelefone = (value: string): string => {
  return value
    .replace(/\D/g, '') // Remove caracteres não numéricos
    .replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses nos dois primeiros dígitos
    .replace(/(\d)(\d{4,4})$/, '$1-$2'); // Coloca o hífen antes dos últimos quatro ou cinco dígitos
};
export const handlePhoneChange = (event: any, handleChange: any): any => {
  const { value, name } = event.target;
  const formattedPhone = formatarNumeroTelefone(value);
  handleChange({
    target: {
      name,
      value: formattedPhone
    }
  });
};
// CNPJ
const formatarCnpj = (value: string): string => {
  return value
    .replace(/\D/g, '') // Remove caracteres não numéricos
    .replace(/^(\d{2})(\d)/, '$1.$2') // Coloca ponto após os primeiros dois dígitos
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Coloca ponto após os próximos três dígitos
    .replace(/\.(\d{3})(\d)/, '.$1/$2') // Coloca a barra após os próximos três dígitos
    .replace(/(\d{4})(\d)/, '$1-$2'); // Coloca o hífen antes dos últimos dois dígitos
};
export const handleCnpjChange = (event: any, handleChange: any): any => {
  const { value, name } = event.target;
  const formattedPhone = formatarCnpj(value);
  handleChange({
    target: {
      name,
      value: formattedPhone
    }
  });
};
// CEP
const formatarCep = (value: string): string => {
  return value
    .replace(/\D/g, '') // Remove caracteres não numéricos
    .replace(/^(\d{5})(\d)/, '$1-$2'); // Coloca o hífen após os primeiros cinco dígitos
};
export const handleCepChange = (event: any, handleChange: any): any => {
  const { value, name } = event.target;
  const formattedPhone = formatarCep(value);
  handleChange({
    target: {
      name,
      value: formattedPhone
    }
  });
};

// recebe um number, string, null, ou undefined e retorna convertido para number (se precisar)
export const converteParaNumero = (valor: any): number => {
  if (valor === null || valor === undefined || valor === '') {
    return 0;
  }
  if (typeof valor === 'number') {
    return valor;
  }
  if (typeof valor === 'string') {
    const numero = Number(valor.replace(',', '.'));
    // numero eh um number
    if (!isNaN(numero)) {
      return numero;
    }
  }
  return 0;
};

// recebe um number, string, null, ou undefined e retorna string com numero no padrao '0,00'
export const converteNumeroParaString = (
  valor: any,
  casasDecimais: number,
  grouping = false // se true coloca o . para separar as casas dos milhares ex:'1.000.000'
): string => {
  if (valor === null || valor === undefined || valor === '') {
    return '0';
  }
  if (typeof valor === 'number') {
    return valor.toLocaleString('pt-BR', {
      minimumFractionDigits: casasDecimais,
      maximumFractionDigits: casasDecimais,
      useGrouping: grouping
    });
  }
  if (typeof valor === 'string') {
    const numero = Number(valor.replace(',', '.'));
    // numero eh um number
    if (!isNaN(numero)) {
      return numero.toLocaleString('pt-BR', {
        minimumFractionDigits: casasDecimais,
        maximumFractionDigits: casasDecimais,
        useGrouping: grouping
      });
    }
  }
  return '0';
};

// recebe uma string e retorna um inteiro
export const converteParaInteiro = (valor: string): number => {
  return parseInt(valor || '0', 10);
};

// recebe um string, null, ou undefined e retorna string
export const ajustaNumeroComVirgula = (
  valor: any
): string => {
  if (valor === null || valor === undefined || valor === '') {
    return '0';
  }
  if (typeof valor !== 'string') {
    valor = valor.toString();
  }
  if (valor.includes('.') || valor.includes(',')) {
    const numero = Number(valor.replace(',', '.'));
    // numero eh um number
    if (!isNaN(numero)) {
      return numero.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false
      });
    }
  }
  return valor;
};

export const obtemUrlDeArquivoNaSessionStorage = async (
  nomeArquivo: string
): Promise<string> => {
  // location é uma propriedade do objeto Window disponível em qualquer contexto da aplicação
  const hostAtual = location.host;
  // sessionStorage é uma propriedade do objeto Window disponível em qualquer contexto da aplicação
  const arquivoSessionStorageString = sessionStorage.getItem(nomeArquivo);
  const arquivoSessionStorage: ArquivoSessionStorage = arquivoSessionStorageString && JSON.parse(arquivoSessionStorageString);

  if (arquivoSessionStorage && arquivoSessionStorage.host === hostAtual) {
    return arquivoSessionStorage.url;
  } else {
    try {
      const url = await firebaseStorage.obtemUrlArquivoNoStorage(nomeArquivo);
      const novoArquivoSessionStorage: ArquivoSessionStorage = { host: hostAtual, url: url };
      sessionStorage.setItem(nomeArquivo, JSON.stringify(novoArquivoSessionStorage));
      return novoArquivoSessionStorage.url;
    } catch (e) {
      const erro = {
        nome: 'FIREBASE-ERRO-DESCONHECIDO',
        mensagem: 'Erro ao obter a URL de um arquivo serviço de armazenamento',
        causa: e
      };
      throw new FarmaError(erro);
    }
  }
}

// Formata dados inputs reportId, wrokspaceId, datasetId para os formato de dado esperado UUID
const formatInputsUuIds = (value: string) => {
  const rawValue = value.replace(/-/g, '').slice(0, 32);
  const sections = [
    rawValue.slice(0, 8),
    rawValue.slice(8, 12),
    rawValue.slice(12, 16),
    rawValue.slice(16, 20),
    rawValue.slice(20, 32),
  ];
  return sections.filter(section => section).join('-');
};

export const handleUuIdChange = (event: any, handleChange: any) => {
  const { value, name } = event.target;
  const rawValue = value.replace(/-/g, '');

  if (rawValue.length > 32) return;

  const newValueUuId = formatInputsUuIds(value);

  handleChange({
    target: {
      name,
      value: newValueUuId,
    },
  });
};

export const alterarCorImagemFundo = (classes: any, svgPath: string) => {
  // Carrega o SVG (imagem de fundo das telas login e empresa inativa)
  fetch(svgPath)
    .then(response => response.text())
    .then(svgText => {
      // muda a cor de fundo do arquivo para a cor primaria
      const modifiedSvg = svgText.replace(/\.fil0\s*{fill:[^;}]+}/g, `.fil0 {fill:${BRAND_PRIMARY_COLOR}}`);
      const svgBlob = new Blob([modifiedSvg], { type: 'image/svg+xml' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const rootElement: any = document.querySelector(`.${classes.root}`);
      if (rootElement) {
        rootElement.style.backgroundImage = `url(${svgUrl})`;
      }
    })
    .catch(e => {
      throw new FarmaError(e);
    });
}

// compara os atributos passados entre dois objetos para validar se são igauais
export const comparaAtributos = (oldData: any, newData: any, atributos: string[]): boolean => {
  if (!oldData || !newData) return false;

  for (const atributo of atributos) {
    if (oldData[atributo] !== newData[atributo]) {
      return false;  // Se qualquer campo for diferente, retorna false
    }
  }

  return true;  // Se todos os campos forem iguais, retorna true
};

